import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import { createPinia } from 'pinia'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faWeixin, faQq, faWeibo, faAlipay } from '@fortawesome/free-brands-svg-icons'
import { faUser, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import ant from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import { Button, message, Form, Input, Checkbox, Menu, Layout, Table, Select, DatePicker, Spin, Switch, ConfigProvider } from 'ant-design-vue'
;('@ant-design/icons-vue')


library.add(faWeixin, faQq, faWeibo, faAlipay, faUser, faLock, faEnvelope)
const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
axios.defaults.withCredentials = true
app.use(router)
app.use(ant)
app.use(createPinia())
app.mount('#app')
app.config.globalProperties.$axios = axios
